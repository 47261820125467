import React, { useState } from 'react';
import uniqolor from 'uniqolor';
import {
  Button,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useAppState } from '../../contexts/app-context';

const POPULAR_STOCKS_COUNT_THRESHOLD = 10;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 3),
  },
  lightBulb: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  rank1: {
    fontSize: theme.spacing(5),
  },
  todayMetrics: {
    width: '100%',
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  unpopularSymbols: {
    marginTop: theme.spacing(1),
  },
}));

function SymbolCount({ symbol, count, fontSize = 25, index = 0 }) {
  return (
    <Grid item>
      <Typography
        style={{
          fontSize: `${fontSize}px`,
          color: uniqolor(count).color,
          textAlign: 'center',
        }}
      >
        {`${symbol} (${count})`}
      </Typography>
    </Grid>
  );
}

export default function WordCloud() {
  const classes = useStyles();
  const { metricsToday = {}, lastUpdated } = useAppState();
  const [unpopularVisible, setUnpopularVisible] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const metrics = Object.entries(metricsToday).map(([symbol, count]) => {
    return [symbol, count];
  });

  const popularSplitIndex = metrics.findIndex(
    ([, count]) => count < POPULAR_STOCKS_COUNT_THRESHOLD,
  );
  const unpopularSymbols = metrics.splice(popularSplitIndex, metrics.length);

  async function toggleUnpopular() {
    setUnpopularVisible(!unpopularVisible);
  }

  return (
    <Grid
      container
      xs={12}
      md={6}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography component="h1" gutterBottom style={{ textAlign: 'center' }}>
          The below is a list of stock symbols mentioned on r/wallstreetbets in
          the last{' '}
          <strong style={{ textDecoration: 'underline' }}>24 hours</strong>. The
          larger the symbol is, the more it has been mentioned in posts and
          comments.
        </Typography>
      </Grid>
      {lastUpdated && (
        <Grid item>
          <Typography variant="caption">Last Updated: {lastUpdated}</Typography>
        </Grid>
      )}
      <Box className={classes.todayMetrics}>
        {metrics.map(([symbol, count], i) => {
          const baseFontSize = isMobile ? 60 : 80;
          const fontSize = Math.max(baseFontSize - i * 5, 25);

          return (
            <SymbolCount
              fontSize={fontSize}
              symbol={symbol}
              count={count}
              index={i}
            />
          );
        })}
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={toggleUnpopular}
        >
          See {unpopularVisible ? 'Less' : 'More'}
        </Button>
        {unpopularVisible && (
          <Box flexDirection="column" style={{ margin: 'auto' }}>
            {unpopularSymbols.map(([symbol, count], i) => (
              <SymbolCount symbol={symbol} count={count} index={i} />
            ))}
          </Box>
        )}
      </Box>
    </Grid>
  );
}
