import axios from 'axios';

import config from '../config';

const { ENDPOINT_URL } = config;

const getTodayMetrics = async (body) => {
  const url = `${ENDPOINT_URL}/metrics/today`;
  const res = await axios({
    method: 'get',
    url,
    responseType: 'json',
  });
  return res.data;
};

const getWeeklyTrend = async (body) => {
  const url = `${ENDPOINT_URL}/metrics/trend`;
  const res = await axios({
    method: 'get',
    url,
    responseType: 'json',
  });
  return res.data;
};

export { getTodayMetrics, getWeeklyTrend };
