import React from 'react';
import Trend from './components/trend';
import WordCloud from './components/word-cloud';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Footer from './components/footer';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
}));

export default function App() {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Typography variant="h5" component="h1" gutterBottom>
        <Link
          href="https://www.reddit.com/r/wallstreetbets"
          className={classes.share}
          rel="noopener"
          target="_blank"
        >
          r/wallstreetbets
        </Link>{' '}
        Trends
      </Typography>
      <Typography variant="caption">
        Disclaimer: this is not financial advice, this is just a tracker for
        what's happening in r/wallstreetbets. Always do your due diligence when
        it comes to taking on financial risk.
      </Typography>
      <Trend />
      <Grid
        container
        direction="column"
        align="center"
        style={{ margin: '10px' }}
      >
        <Divider light={true} style={{ background: '#ccc', margin: '15px' }} />
        <Grid item style={{ margin: '5px' }}>
          I ain't a financial advisor, so always do your due diligence, here's a{' '}
          <Link
            href="https://amzn.to/3y6kb3U"
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            good book
          </Link>{' '}
          on investing
        </Grid>
        <Grid item>
          <a
            href="https://www.amazon.com/Intelligent-Investor-Definitive-Investing-Essentials/dp/0060555661?dchild=1&keywords=The+Intelligent+Investor%3A&qid=1624998345&sr=8-1&linkCode=li2&tag=pthieu0e-20&linkId=87c75a44e14cd45da6a6cda2af99e399&language=en_US&ref_=as_li_ss_il"
            target="_blank"
          >
            <img
              border="0"
              src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0060555661&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=pthieu0e-20&language=en_US"
            />
          </a>
        </Grid>
        <Divider light={true} style={{ background: '#ccc', margin: '15px' }} />
      </Grid>
      <WordCloud />
      {/*</Box><Copyright />*/}
      <Footer />
    </Grid>
  );
}
