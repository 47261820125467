import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.background.dark,
  },
  share: {
    backgroundSize: 'contain',
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'underline',
    margin: theme.spacing(0, 0.5),
  },
}));
