import React from 'react';
import { Twitter as TwitterIcon } from '@material-ui/icons';
import { Box, Grid, Link } from '@material-ui/core';

import useStyles from './style';

export const Footer = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.footer}
      justify="center"
      direction="column"
    >
      <Box display="flex" justifyContent="center">
        Have suggestions? Tweet or DM me:
        <Link
          href="https://twitter.com/PhongGT"
          className={classes.share}
          rel="noopener"
          target="_blank"
        >
          <TwitterIcon />
          <span>@PhongGT</span>
        </Link>
      </Box>
      <Box display="flex" justifyContent="center">
        [Feb 15]
        <Link
          href="https://youtu.be/gtownEN_vxU"
          className={classes.share}
          rel="noopener"
          target="_blank"
        >
          See how I was able to make a little money and how to build this site
        </Link>
      </Box>
    </Grid>
  );
};

export default Footer;
