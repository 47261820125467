import React, { useEffect, useState } from 'react';
import uniqolor from 'uniqolor';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Typography,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Line } from '@reactchartjs/react-chart.js';
import { getWeeklyTrend } from '../../services/api';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    color: theme.palette.primary.main,
    '& .MuiGrid-item': {
      margin: theme.spacing(1, 0),
    }
  },
}));

function sortSymbolCountDict(symbolDict) {
  return Object.entries(symbolDict).sort(([, a], [, b]) => b - a);
}

async function formatData(payload, numToConsider = 3) {
  const previousSymbols = {};

  const symbolsData = payload.map(({ data }) => {
    const sorted = sortSymbolCountDict(data);
    const top = sorted.splice(0, numToConsider);
    for (const s of top) {
      previousSymbols[s[0]] = true;
    }

    for (const s of sorted) {
      if (previousSymbols[s[0]]) {
        top.push(s);
      }
    }
    return top;
  });

  const topSymbolList = [
    ...new Set(
      JSON.parse(
        JSON.stringify(
          JSON.stringify(symbolsData).match(/"([A-Z]+)"/g),
        ).replace(/\\"/g, ''),
      ),
    ),
  ];

  const topSymbolDict = topSymbolList.reduce((acc, s) => {
    acc[s] = [];
    return acc;
  }, {});

  const labels = [];

  payload
    .sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    })
    .reduce(
      (acc, { date, data }) => {
        acc.labels.push(
          new Date(date).toLocaleString([], {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
          }),
        );
        topSymbolList.map((s) => {
          acc.symbols[s].push(data[s]);
        });
        return acc;
      },
      { labels, symbols: topSymbolDict },
    );
  const datasets = Object.entries(topSymbolDict).map(([symbol, dataset], i) => {
    return {
      label: symbol,
      data: dataset,
      fill: false,
      pointRadius: 2,
      pointHoverRadius: 8,
      // backgroundColor: uniqolor(10 + i * 10).color,
      borderColor: uniqolor(10 + i * 10).color,
    };
  });

  return {
    labels,
    datasets,
  };
}

export default function WordCloud() {
  const classes = useStyles();
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [topNStocks, setTopNStocks] = useState('2');

  const theme = useTheme();
  const isMobile = window.outerWidth <= 768;

  useEffect(() => {
    async function getData() {
      const payload = await getWeeklyTrend();
      const formattedData = (await formatData(payload, topNStocks)) || {
        labels: [],
        datasets: [],
      };
      setData(formattedData);
    }
    getData();
  }, [topNStocks]); // eslint-disable-line

  async function handleTopNStockChange(event) {
    setTopNStocks(event.target.value);
  }

  return (
    <Grid
      className={classes.root}
      container
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography component="h1" gutterBottom>
          - The line chart below indicates the trends of popular symbols in the
          last week.
        </Typography>
        <Typography component="h1" gutterBottom>
          - Stocks are only counted once per occurrence per message to avoid
          messages with repeated symbols.
        </Typography>
        <Typography component="h1" gutterBottom>
          - Each snapshot is at the end of a 24-hour period.
        </Typography>
      </Grid>
      <Grid item>
        <FormControl component="fieldset" style={{ alignItems: 'center' }}>
          <FormLabel
            component="legend"
            style={{ textAlign: 'center', color: theme.palette.primary.main }}
          >
            How many stocks do you want to see?
          </FormLabel>
          <RadioGroup
            row={!isMobile}
            aria-label="How many stocks to see"
            name="topNStocks"
            value={topNStocks}
            onChange={handleTopNStockChange}
          >
            <FormControlLabel
              value="1"
              control={<Radio size="small" />}
              label="Less"
            />
            <FormControlLabel
              value="2"
              control={<Radio size="small" />}
              label="Standard"
            />
            <FormControlLabel
              value="3"
              control={<Radio size="small" />}
              label="More"
            />
            <FormControlLabel
              value="4"
              control={<Radio size="small" />}
              label="Most"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Line
          data={data}
          options={{
            color: '#fff',
            maintainAspectRatio: false,
            legend: {
              labels: {
                fontColor: 'white',
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: 'white',
                  },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: '# of Mentions',
                    fontColor: 'white',
                    fontSize: 12,
                  },
                  ticks: {
                    fontColor: 'white',
                  },
                },
              ],
            },
          }}
          height={isMobile ? 300 : 600}
        />
      </Grid>
    </Grid>
  );
}
