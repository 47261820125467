import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useReducer } from 'react';

import { getTodayMetrics } from '../services/api';

const AppStateContext = createContext();
const AppDispatchContext = createContext();

export const TYPES = {
  LOADING_DONE: 'LOADING_DONE',
  METRICS_TODAY: 'METRICS_TODAY',
};

function AppReducer(state, action) {
  const { type, data } = action;

  switch (type) {
    case TYPES.LOADING_DONE: {
      return { ...state, loading: false };
    }
    case TYPES.METRICS_TODAY: {
      const symbols = data?.data;
      const sortedSymbols = Object.entries(symbols)
        .sort(([, a], [, b]) => b - a)
        .reduce((acc, [symbol, count]) => ({ ...acc, [symbol]: count }), {});
      return {
        ...state,
        metricsToday: sortedSymbols,
        lastUpdated: new Date(data.date).toLocaleString(),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(AppReducer, {
    metricsToday: {},
    lastUpdated: null,
    loading: true,
  });

  useEffect(() => {
    async function fetchTodayMetrics() {
      const payload = await getTodayMetrics();
      dispatch({
        type: TYPES.METRICS_TODAY,
        data: payload,
      });
      dispatch({
        type: TYPES.LOADING_DONE,
      });
    }
    fetchTodayMetrics();
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node,
};

export function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

export function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return context;
}

export function useApp() {
  return [useAppState(), useAppDispatch()];
}

const properties = {
  TYPES,
  AppProvider,
  useApp,
  useAppState,
  useAppDispatch,
};

export default properties;
